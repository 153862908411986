


















































































































import { Component, Vue } from "vue-property-decorator";
import { usuario } from "@/shared/dtos/usuario";
import { MessageService } from "@/shared/services/message-service";
import usuarioModule from "@/store/modules/usuario-module";
import { singleOrDoubleRowClick } from "@/utils/dblclickrow";
import rolModule from "@/store/modules/rol-module";
@Component({
  components: {},
  $_veeValidate: { validator: "new" },
})
export default class UsuariosLista extends Vue {
  public search: string = "";
  public show_dialog: boolean = false;
  public obj_usuario: usuario = new usuario();
  public es_edicion: boolean = false;
  public radioGroup: number = 1;

  public created() {
    usuarioModule.getusuarios();
    rolModule.getroles();
  }

  public get datasource() {
    return usuarioModule.usuarios;
  }

  public get headers() {
    let header: any = [
      {
        text: "Nombre",
        align: "start",
        value: "nombre",
      },
      { text: "Email", value: "email" },
      { text: "Teléfono", value: "telefono" },
    ];

    header.push({ text: "", value: "acciones", align: "end" });

    return header;
  }

  public get datasource_roles() {
    return rolModule.roles;
  }

  /**
   * editar
   */
  public editar_rw(obj: usuario) {
    this.obj_usuario = new usuario(obj);
    this.es_edicion = true;
    this.show_dialog = true;
  }

  /**
   * add_new
   */
  public add_new() {
    this.$validator.reset();
    this.show_dialog = true;
  }
  /**
   *eliminar
   */
  public eliminar(obj: usuario) {
    MessageService.confirm(
      "¿Seguro que quiere eliminar " + obj.nombre + "?",
      "Eliminar",
      (x: boolean) => {
        if (x) {
          usuarioModule.eliminarusuario(obj);
        }
      }
    );
  }

  /**
   * cancelar
   */
  public cancelar() {
    this.show_dialog = false;
    this.obj_usuario = new usuario();
    this.es_edicion = false;
  }

  /**
   * aceptar
   */
  public aceptar() {
    this.$validator.validateAll().then((allOk) => {
      if (allOk) {
        if (this.es_edicion) {
          usuarioModule
            .modificarusuario(this.obj_usuario)
            .then(() => this.cancelar());
        } else {
          usuarioModule
            .guardarusuario(this.obj_usuario)
            .then(() => this.cancelar());
        }
      }
    });
  }
  public dblclick(item: any, singleClickFunc: any, doubleClickFunc: any) {
    singleOrDoubleRowClick(item, singleClickFunc, doubleClickFunc);
  }
}
